<template>
    <div class="content-box">
        <header>
            <a href="#" @click="jumpUrl"><svg t="1604050192245" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1543"><path d="M983.7 468H132.5l408-404.8c14.9-14.9 14.9-37.5 0-52.4-14.9-14.9-39.1-14.1-54 0.8L13.3 485.2c-7.2 7.2-11.2 17.1-11.2 27.2 0 10.1 4 20 11.2 27.1l473.2 473.3c7.5 7.5 17.2 11.2 27 11.2s19.5-3.7 27-11.2c14.9-14.9 14.9-38.5 0-53.4L132.5 552h851.2c21.1 0 38.2-20.9 38.2-42s-17.1-42-38.2-42z" p-id="1544" fill="#ffffff"></path></svg></a>
            无需下载，注册即可畅玩
        </header>

        <div class="conten-main" v-show="is_show">
            <section class="paly-game">
                <div class="game-box">
                    <div class="game-img">
                        <img :src="gameInfo.ico" alt="">
                    </div>
                    <div class="game-info">
                        <h3>{{gameInfo.game_name}}</h3>
                        <p>{{gameInfo.game_desc}}</p>
                    </div>
                </div>
                <div class="open-btn">
                    <a href="#" @click="openGame()">开始游戏</a>
                </div>
            </section>

            <section class="screenshots">
                <h1 class="title">游戏截图</h1>

                <ul class="screenshots-main">
                    <li v-for="item in gameList">
                        <img :src="item" alt="">
                    </li>
                </ul>
            </section>

            <section class="gift-main" v-if="gameGiftList.length > 0">
                <h1 class="title">游戏礼包</h1>
                <ul class="gift-list">
                    <li v-for="item in gameGiftList">
                        <div class="gift-info">
                            <h3>{{item.gift_name}}</h3>
                            <p>{{item.desc}}</p>
                        </div>
                        <div class="gift-get-btn">
                            <a href="#" @click="getGift(item.id)">领取</a>
                        </div>
                    </li>
                </ul>
            </section>
        </div>

        <LoginDialog :PCcenterDialogVisible="DialogVisible" :gameId="app_id" @childFunPhone="childFun"></LoginDialog>
    </div>
</template>

<script>
import LoginDialog from "@/components/LoginDialog/LoginDialog";
export default {
    name: "DetailPhone",
    data () {
        return {
            game_id: null,
            gameInfo: [],
            gameList: [],
            game_icon: 'http://app.yunjiwan.com',
            is_show: true,
            app_id: null,
            gameGiftList: [],
            DialogVisible: false
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        this.game_id = this.$route.query.gameId
        this.getGameDetail()
    },
    methods: {
        // 获取游戏详情信息
        async getGameDetail() {
            this.h5Request({
                url: '/api/h5/detail/id/' + this.game_id + '/'
            }).then(result => {
                if(result.data.code == 0) {
                    result.data.data.ico = this.game_icon + result.data.data.ico
                    this.app_id = result.data.data.app_id
                    for(let item in result.data.data) {
                        if(item.indexOf('game_screen') != -1) {
                            this.gameList.push(this.game_icon + result.data.data[item]);
                        }
                    }
                    this.gameInfo = result.data.data
                    document.title = this.gameInfo.game_name
                    this.is_show = true
                    this.getGameGift()
                }
            })
        },
        jumpUrl () {
            this.$router.push('/')
        },
        openGame () {
            var token = this.Public.getCookie('token');
            if(token != 'null' && token) {
                var routeData = this.$router.resolve({
                    path: "/play",
                    query: {
                        gameId: this.app_id
                    }
                });
                window.open(routeData.href, '_blank');
                return;
            } else {
                console.log(1)
                this.DialogVisible = true
            }
        },
        // 获取游戏的礼包
        async getGameGift () {
            await this.h5Request({
                url: "/api/h5/gift/list/?game_id=" + this.app_id
            }).then(result => {
                if(result.data.code == 0) {
                    this.gameGiftList = result.data.data
                }
            })
        },
        // 用户领取游戏礼包
        getGift(giftId) {
            if(this.$store.state.token != 'null' && this.$store.state.token) {
                this.h5Request({
                    url: '/api/h5/gift/get/?u_token='+this.$store.state.token+'&id='+giftId
                }).then(result => {
                    if(result.data.code == 200) {
                        this.$message.success('礼包领取成功！');
                    } else {
                        this.$message.error('获取游戏信息失败，无法正常进行游戏，请联系管理员！');
                    }
                })
            } else {
                this.DialogVisible = true
            }
        },
        childFun(value) {
            this.DialogVisible = value
        }
    },
    components: {
        LoginDialog
    }
}
</script>

<style scoped lang="less">

    .title {
        position: relative;
        font-size: 0.48875rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #383838;
        box-sizing: border-box;
        padding-left: 0.2875rem;
        margin-bottom: .1rem;
    }

    .title::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0.1333rem;
        height: 100%;
        background: #FF7431;
        opacity: 1;
        border-radius: 8px;
    }

    .game-box {
        display: flex;
    }

    .game-box .game-img img {
        width: 1.5rem;
        height: 1.5rem;
    }

    .game-box .game-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        padding-right: 10px;
    }

    .game-box .game-info h3 {
        font-size: .386667rem;
        font-weight: 700;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .game-box .game-info p{
        font-size: .296667rem;
        margin-top: 5px;
        color: #999;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .open-btn {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .open-btn a {
        display: inline-block;
        width: 1.5rem;
        height: 30px;
        line-height: 30px;
        margin-top: 10px;
        border-radius: 8px;
        background: #FF7431;
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        text-align: center;
    }

    .content-box {
        width: 100%;

        header {
            position: relative;
            width: 100%;
            height: 1.08rem;
            line-height: 1.08rem;
            text-align: center;
            color: #FFFFFF;
            font-size: .38rem;
            font-weight: 700;
            background-color: #FF7431;

            svg {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translate(0, -50%);
                width: .5rem;
                color: #ffffff;
            }
        }

        .conten-main {
            .paly-game {
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                box-sizing: border-box;
                padding: 15px 10px 15px 10px;
            }

            .screenshots {
                width: 100%;
                box-sizing: border-box;
                padding: 15px 10px 15px 10px;
                margin-top: 15px;
                background-color: #FFFFFF;

                .screenshots-main {
                    display: flex;
                    overflow: auto;

                    li {
                        flex-shrink: 0;
                        width: 3rem;
                        height: auto;
                        margin-right: .22rem;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .screenshots-main li:last-child{
                        margin-right: 0;
                    }
                }
            }

            .gift-main {
                padding: 15px 10px 15px 10px;
                background: #FFFFFF;

                .gift-list {
                    li {
                        display: flex;
                        box-sizing: border-box;
                        padding: .3rem 10px;
                        border-bottom: 1px solid #fe9d8b;

                        .gift-info {
                            width: 80%;

                            h3 {
                                font-size: .4rem;
                                font-weight: bolder;
                                line-height: 1.5;
                            }
                            p {
                                margin-top: 5px;
                                font-size: .346667rem;
                                color: #999;
                            }
                        }

                        .gift-get-btn {
                            display: flex;
                            flex-direction: row-reverse;
                            width: 20%;

                            a {
                                display: inline-block;
                                width: 85%;
                                height: 30px;
                                line-height: 30px;
                                background-color: #FF7431;
                                border-radius: 8px;
                                color: #fff;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>